import { Fragment, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import NewTabIcon from '@mui/icons-material/OpenInNewOutlined'
import ErrorIcon from '@mui/icons-material/Error'
import DeleteIcon from '@mui/icons-material/HighlightOffOutlined'

import { Permission, usePermission } from '../../../../../modules/users'
import { useMoveState } from '../../../../../modules/moves'
import { type FullMove } from '../../../../../modules/adminMoves'
import {
  useGetPaymentGatewayTransactionUrl,
  useGetPaymentTotalCharge,
} from '../../../../../modules/payment'
import { useDateFormatter } from '../../../../../utils/date'
import { capitalizeFirstLetter } from '../../../../../utils/string'
import { formatPrice } from '../../../../../utils/money'
import Table, {
  Column,
  Row,
  Cell,
} from '../../../../../components/Table'
import Link, {
  LinkVariant,
} from '../../../../../components/Link'
import PaymentStatusTag from '../../../../../components/payment/PaymentStatusTag'
import ConfirmationModal from '../../../../../components/ConfirmationModal'
import { Button } from '@mui/material'
import { useDeletePaymentAction } from '../../../../../modules/payment/core/payments.actions.hooks'
import { useErrorFormatter } from '../../../../../components/errors/useErrorFormatter'
import { errorNotification, successNotification } from '../../../../../components/ToastNotifications'

type PaymentsTableProps = {
  move: FullMove
}

const PaymentsTable: React.FC<PaymentsTableProps> = ({
  move,
}) => {
  const { t } = useTranslation()
  const { currency } = useMoveState(move)
  const getPaymentGatewayTransactionUrl = useGetPaymentGatewayTransactionUrl()
  const formatDate = useDateFormatter()
  const getPaymentTotalCharge = useGetPaymentTotalCharge()
  const canChargeCustomers = usePermission(Permission.canChargeCustomers)
  const [deletePayment] = useDeletePaymentAction()
  const formatError = useErrorFormatter()

  const sortedPayments = useMemo(() => {
    return [...move.payments].sort((a, b) => {
      const aDate = new Date(a.paymentDate ?? a.createdAt)
      const bDate = new Date(b.paymentDate ?? b.createdAt)
      if (aDate < bDate) {
        return 1
      }
      if (aDate > bDate) {
        return -1
      }
      return 0
    })
  }, [move.payments])

  const handleDeletePayment = async (paymentId: string) => {
    try {
      await deletePayment(move.id, paymentId)
      successNotification(t('payment.deleteSuccess'))
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  const ZERO_CHARGE = {
    price: 0,
    currency,
  }

  if (move.payments.length === 0) {
    return (
      <div className="pb-4 text-center text-neutral-500">
        { t('payment.table.empty') }
      </div>
    )
  }

  return (
    <Table
      columns={(
        <>
          <Column
            className="min-w-[275px]"
          >
            { t('payment.table.columns.paymentDate') }
          </Column>

          <Column expand>
            { t('payment.table.columns.object') }
          </Column>

          <Column>
            { t('payment.table.columns.status') }
          </Column>

          <Column>
            { t('payment.table.columns.source') }
          </Column>

          <Column
            right
            className="min-w-[200px]"
          >
            { t('payment.table.columns.amount') }
          </Column>

          <Column
            right
            className="min-w-[200px]"
          >
            { t('payment.table.columns.total') }
          </Column>

          { canChargeCustomers && <Column className="empty:!p-0" /> }
        </>
      )}
      className="lg:max-w-[calc(100vw-200px)]"
    >
      { sortedPayments.map(payment => {
        const paymentGatewayUrl = getPaymentGatewayTransactionUrl(payment)
        const total = getPaymentTotalCharge(payment)

        return (
          <Fragment key={payment.id}>
            <Row borderLess={!!payment.dispute}>
              <Cell noWrap>
                { formatDate(
                  payment.paymentDate ?? payment.createdAt,
                  {
                    showTime: !payment.paymentDate,
                  }) }
              </Cell>

              <Cell noWrap>
                <div className="flex items-center gap-x-4">
                  {
                     payment.isDeposit
                       ? t('payment.table.deposit')
                       : t('payment.table.payment')
                     }
                </div>
              </Cell>

              <Cell noWrap>
                <PaymentStatusTag payment={payment} />
              </Cell>

              <Cell>
                { payment.externalSource
                  ? (
                    <span>
                      { t(`payment.externalSource.${payment.externalSource}`, {
                        defaultValue: payment.externalSource,
                      }) }
                    </span>
                    )
                  : (
                    <span>
                      { t(
                  `payment.gateway.${String(payment.gateway)}`,
                  { defaultValue: capitalizeFirstLetter(payment.gateway) },
                      ) }

                      <div className="text-sm text-neutral-400">
                        { paymentGatewayUrl
                          ? (
                            <Link
                              to={paymentGatewayUrl}
                              variant={LinkVariant.Underlined}
                              target="_blank"
                              className="inline-flex gap-x-2"
                            >
                              { payment.gatewayId }
                              <NewTabIcon className="!text-[18px]" />
                            </Link>
                            )
                          : payment.gatewayId }
                      </div>
                    </span>
                    ) }

              </Cell>

              <Cell
                right
                noWrap
              >
                { formatPrice(payment.amount) }
                { payment.refundedAmount && (
                  <div className="text-sm text-red-400">
                    { t('payment.table.refunded') }:
                    { ' ' }
                    { formatPrice(payment.refundedAmount) }
                  </div>
                ) }
              </Cell>

              <Cell
                right
                noWrap
              >
                <div className="font-bold">
                  { formatPrice(total ?? ZERO_CHARGE, { showCurrency: !!total }) }
                </div>
              </Cell>

              { canChargeCustomers && (
                <Cell className="empty:!p-0">
                  { payment.externalSource && (
                    <ConfirmationModal
                      question={t('payment.deleteConfirmation')}
                      onConfirm={async () => { await handleDeletePayment(payment.id) }}
                    >
                      <Button
                        variant="contained"
                        color="error"
                        size="small"
                        className="!normal-case"
                        startIcon={<DeleteIcon />}
                      >
                        { t('actions.delete') }
                      </Button>
                    </ConfirmationModal>
                  ) }
                </Cell>
              ) }
            </Row>

            { payment.dispute && (
              <>
                <Row
                  disableHover
                  borderLess
                  className="child:py-1"
                >
                  <Cell colSpan={6} />
                </Row>
                <Row
                  disableHover
                  borderLess
                  className="h-full bg-red-50 text-sm child:text-red-400 [&_td]:py-1"
                >
                  <Cell
                    className="h-full bg-white !py-0 pr-0"
                    noWrap
                  >
                    <div className="flex h-full items-center rounded-l-full bg-red-50 pl-2 pr-4">
                      <ErrorIcon className="mr-1 !text-[20px]" />
                      { ' ' }
                      { t('payment.table.dispute.dispute') }
                      <div className="ml-1 lowercase">
                        ({ t(
                            `payment.disputeStatus.${String(payment.dispute.status)}`,
                            { defaultValue: payment.dispute.status },
                      ) })
                      </div>
                    </div>
                  </Cell>

                  <Cell noWrap>
                    { formatDate(payment.dispute.disputeDate, { showTime: true }) }
                  </Cell>

                  <Cell noWrap>
                    { payment.dispute.fee && (
                      <div>
                        <span className="text-red-400">
                          { t('payment.table.dispute.fee') }:
                          { ' ' }
                        </span>
                        { formatPrice(payment.dispute.fee) }
                      </div>
                    ) }
                  </Cell>

                  <Cell
                    colSpan={3}
                    className="h-full bg-white !py-0 pl-0"
                  >
                    <div className="flex h-full items-center rounded-r-full bg-red-50 pl-6 pr-4">
                      <div className="mr-1 text-red-400">
                        { t('payment.table.dispute.reason') }:
                        { ' ' }
                      </div>
                      <div>
                        { payment.dispute.reason }
                      </div>
                    </div>
                  </Cell>
                </Row>
                <Row disableHover className="child:py-3">
                  <Cell colSpan={6} />
                </Row>
              </>
            ) }
          </Fragment>
        )
      }) }
    </Table>
  )
}

export default PaymentsTable
