import { NetworkStatus } from '@apollo/client'

import { type DataAdapter } from './graphQl.type'

/**
 * get the correct Apollo FetchPolicy depending on whether we want to fetch or reload the data
 */
export const getFetchPolicy = ({ reload }: { reload: boolean }) => reload ? 'network-only' : 'cache-first'

/**
 * returns true if we are loading the data for the first time
 */
export const isLoading = (networkStatus: NetworkStatus) => ([
  NetworkStatus.loading,
  NetworkStatus.setVariables,
]).includes(networkStatus)

/**
 * returns true if we are reloading the data
 */
export const isReloading = (networkStatus: NetworkStatus) => ([
  NetworkStatus.refetch,
  NetworkStatus.poll,
]).includes(networkStatus)

/**
 * format Apollo response data
 *  - unwind first key if the response is an object with only 1 key
 *  - remove all __typename properties
 *  - use an adapter if one was provided
 */
export const formatData = <TData>(data: any, adapter?: DataAdapter<TData>): TData => {
  const rawData = removeTypeNameFromObject(getFirstDataObject(data))
  return adapter ? adapter(rawData) : rawData as TData
}

/**
 * unwind first key if the response is an object with only 1 key
 */
const getFirstDataObject = <TData extends Record<string, any>>(data: TData): TData => {
  if (typeof data !== 'object') {
    return data
  }
  const firstKey = Object.keys(data)?.[0]
  return data?.[firstKey]
}

/**
 * remove all __typename properties
 */
const removeTypeNameFromObject = <TData extends Record<string, any>>(data?: any): TData => {
  if (typeof data !== 'object') {
    return data
  }
  try {
    return JSON.parse(
      JSON.stringify(data, (key, value) => {
        if (key === '__typename') {
          return
        }
        return value
      }),
    )
  } catch (error) {
    return data
  }
}
