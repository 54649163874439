import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

import { useFloatValidator } from '../../../../utils/number'
import { type CloseJobFormValues } from './CloseJobForm.types'

const useValidationSchema = () => {
  const { t } = useTranslation()
  const floatValidator = useFloatValidator()

  const hoursValidator = floatValidator({ min: 0, max: 15, maxDecimals: 1, required: false })
  const kmsValidator = floatValidator({ min: 0, max: 5000, maxDecimals: 1, required: false })
  const stairsValidator = floatValidator({ min: 0, max: 20, maxDecimals: 0, required: false })

  return Yup.object({
    movingLabour: hoursValidator,
    transportLabour: hoursValidator,
    packingLabour: hoursValidator,

    travelTime: hoursValidator,
    travelDistance: kmsValidator,
    transportDistance: kmsValidator,

    nbStairs: stairsValidator,

    confirmation: Yup.boolean().oneOf([true], t('formValidation.required')).required(),
  }) as Yup.ObjectSchema<CloseJobFormValues, CloseJobFormValues>
}

export default useValidationSchema
