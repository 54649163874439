import { type DocumentNode } from 'graphql'
import {
  type OperationVariables,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client'

import {
  type QueryOptions,
  type QueryResult,
  type LazyQueryOptions,
  type LazyQueryResult,
  type PaginatedQueryResultData,
  type PaginatedVariables,
  type MutationOptions,
  type MutationResult,
} from './graphQl.type'
import { formatData, getFetchPolicy, isLoading, isReloading } from './graphQl.utils'

/**
 * wrapper around Apollo useQuery
 */
export const useData = <TData, TVariables extends OperationVariables = any>(
  query: DocumentNode,
  options?: QueryOptions<TData, TVariables>,
): QueryResult<TData> => {
  const {
    variables,
    reload = false,
    skip = false,
    usePreviousData = false,
    adapter,
  } = options ?? {}
  const {
    data,
    previousData,
    called,
    loading,
    networkStatus,
    error,
    refetch,
  } = useQuery(
    query,
    {
      skip,
      fetchPolicy: getFetchPolicy({ reload }),
      variables,
    },
  )

  const dataToUse = usePreviousData ? (data ?? previousData) : data

  return {
    data: dataToUse ? formatData(dataToUse, adapter) : undefined,
    called,
    loading: loading && isLoading(networkStatus),
    reloading: loading && isReloading(networkStatus),
    error,
    refetch: async () => {
      const { data } = await refetch()
      return formatData<TData>(data, adapter)
    },
  }
}

/**
 * wrapper around Apollo useLazyQuery
 */
export const useLazyData = <TData, TVariables extends OperationVariables = any>(
  query: DocumentNode,
  options?: LazyQueryOptions<TData>,
): LazyQueryResult<TData, TVariables> => {
  const {
    reload = false,
    usePreviousData = false,
    adapter,
  } = options ?? {}

  const [fetch, {
    data,
    previousData,
    called,
    loading,
    networkStatus,
    error,
  }] = useLazyQuery(
    query,
    {
      fetchPolicy: getFetchPolicy({ reload }),
    },
  )

  const dataToUse = usePreviousData ? (data ?? previousData) : data

  return {
    data: dataToUse ? formatData(dataToUse, adapter) : undefined,
    called,
    loading: loading && isLoading(networkStatus),
    error,
    fetch: async (variables?: TVariables) => {
      const { data, error } = await fetch({ variables })
      if (error) {
        throw error
      }
      return formatData<TData>(data, adapter)
    },
  }
}

/**
 * helper to fetch paginated data
 */
export const usePaginatedData = <TData, TVariables extends PaginatedVariables = any>(
  query: DocumentNode,
) => {
  return useLazyData<PaginatedQueryResultData<TData>, TVariables>(
    query,
    {
      reload: true,
      usePreviousData: true,
    },
  )
}

/**
 * wrapper around Apollo useMutation
 */
export const useAction = <TData = any, TVariables extends OperationVariables = any>(
  mutation: DocumentNode,
  options?: MutationOptions<TData>,
): MutationResult<TData, TVariables> => {
  const {
    adapter,
  } = options ?? {}

  const [mutate, data] = useMutation(mutation)

  const execute = async (variables?: TVariables): Promise<TData> => {
    const { data } = await mutate({ variables })
    return formatData<TData>(data, adapter)
  }
  return [execute, {
    ...data,
    data: formatData<TData>(data, adapter),
  }]
}

type Formatter = (...args: any) => any

/**
 * a helper when the action payload is different from the mutation payload
 */
export const useActionWithPayload = <TData, TFormatter extends Formatter, TVariables extends Parameters<TFormatter>>(
  action: ReturnType<typeof useAction<TData>>,
  formatter: TFormatter,
) => {
  const [mutate, data] = action

  const execute = async (...variables: TVariables) => {
    return await mutate(formatter(...variables))
  }

  return [execute, data] as [typeof execute, typeof data]
}
