import { useMemo } from 'react'

import { useCurrentUser } from '../../users.data.hooks'
import { useRoles } from '../permissions.data.hooks'
import { getPermissionsInRoles, hasPermission } from '../permissions.util'

export const usePermission = (permission: string) => {
  const { data: user } = useCurrentUser()
  const { data: roles = [] } = useRoles()
  const userRoles = user?.roles
  const permissions = useMemo(() => getPermissionsInRoles(roles, userRoles ?? []), [roles, userRoles])

  return hasPermission(permissions, permission)
}
