import { useTranslatedValue } from '../../../../common/translation'
import { useCurrentCompany, useCurrentCompanyBranch } from '..'

/**
 * returns currently selected company branch name (if not defined, we use the parent company name)
 */
export const useCurrentCompanyBranchName = () => {
  const { data: currentBranch } = useCurrentCompanyBranch()
  const { data: currentCompany } = useCurrentCompany()
  const getTranslation = useTranslatedValue()

  return getTranslation(currentBranch?.identification?.name) ??
  getTranslation(currentCompany?.identification.name)
}
