import { useData, type DataOptions } from '../../../graphQl'
import * as queries from './permissions.queries'
import { type Role } from './permissions.models'
import { useAuthenticationState } from '..'

/**
 * returns all roles
 */
export const useRoles = (dataOptions?: DataOptions) => {
  const { isAuthenticated } = useAuthenticationState()

  return useData<Role[]>(
    queries.getRoles,
    {
      ...dataOptions,
      skip: !!dataOptions?.skip || !isAuthenticated,
    },
  )
}
