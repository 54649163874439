import { useRef, useState, useEffect } from 'react'
import InputAdornment from '@mui/material/InputAdornment'

import { type InputProps, CustomControl } from '../../'
import { Currency } from '../../../../common/amount'
import { getCurrencyDetails } from '../../../../utils/money/getCurrencyDetails'

export type PriceInputProps = {
  allowDecimal?: boolean
  maxLength?: number
  endAdornment?: React.ReactNode
  currency?: Currency
} & InputProps

const PriceInput: React.FC<PriceInputProps> = ({
  allowDecimal = true,
  maxLength = 8,
  endAdornment,
  currency = Currency.CAD,
  size = 'small',
  ...inputProps
}) => {
  endAdornment ??= getCurrencyDetails(currency).symbol
  const inputRef = useRef<HTMLInputElement>()
  const [cursor, setCursor] = useState<number>(1)
  const [formattedFieldValue, setValue] = useState<string>()

  /**
   * preserv cursor position
   */
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.setSelectionRange(cursor, cursor)
    }
  }, [cursor, formattedFieldValue])

  /**
   * format price value
   */
  const formatter = (fieldValue?: string) => {
    if (inputRef.current?.selectionStart) {
      setCursor(inputRef.current.selectionStart)
    }
    if (!fieldValue) {
      return ''
    }

    if (formattedFieldValue && fieldValue.length <= formattedFieldValue.length) {
      setValue(fieldValue)
      return fieldValue
    }

    const value = fieldValue.replace('..', '.')
    const isValid = /^[0-9]*(\.[0-9]*)?$/.test(value)

    if (!isValid) {
      const formattedValue = '0.00'
      setValue(formattedValue)
      setCursor(1)
      return formattedValue
    }

    let formattedValue

    if (fieldValue === '.') {
      formattedValue = `0${fieldValue}`
      setCursor(cursor + 1)
    }

    if (fieldValue[fieldValue.length - 1] === '.') {
      fieldValue += '0'
    }

    formattedValue = String((Math.round(parseFloat(fieldValue) * 100) / 100).toFixed(2))
    setValue(formattedValue)
    return formattedValue
  }

  return (
    <CustomControl
      outgoingDataFormatter={allowDecimal ? formatter : undefined}
      applyLocally={allowDecimal}
      size={size}
      inputProps={{
        ref: inputRef,
        maxLength,
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <div className="xl:w-[35px] xl:text-right">
              { endAdornment }
            </div>
          </InputAdornment>
        ),
      }}
      incomingDataFormatter={(incomingValue: string) => incomingValue}
      {...inputProps}
    />
  )
}

export default PriceInput
